import React, { useState, useEffect, useRef } from "react";
import { Line, Bar } from "react-chartjs-2";
import { startActionWithPromise } from "../../../helpers/saga-promise-helpers";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Spinner
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import { useDispatch } from "react-redux";
import { getHourlyActiveSessionsAction } from "../../../redux/reducers/reportReducer";

const SessionDurationWidget = ({ refresh }) => {
    const dispatch = useDispatch();

    const [asOfDate, setAsOfDate] = useState(new Date());
    const [hourlyActiveSessions, setHourlyActiveSessions] = useState({})

    const formatDuration = (given_seconds) => {
        const hours = Math.floor(given_seconds / 3600);
        const minutes = Math.floor((given_seconds - (hours * 3600)) / 60);
        const seconds = given_seconds - (hours * 3600) - (minutes * 60);

        const timeString = hours.toString().padStart(2, '0') + ':' +
            minutes.toString().padStart(2, '0') + ':' +
            seconds.toString().padStart(2, '0');
        return timeString;
    }

    const getHourlyActiveSessions = async () => {
        try {
            const now = new Date();
            let data = await startActionWithPromise(
                getHourlyActiveSessionsAction,
                {
                    params: {
                        query: 'DURATION',
                        asOfDate: asOfDate,
                        minsTimeOffset: now.getTimezoneOffset(),
                    },
                    successCallback: () => { },
                    failedCallback: () => { },
                },
                dispatch
            );
            setHourlyActiveSessions(data);
        } catch { }
    }

    const fetchData = () => {
        (async () => {
            await getHourlyActiveSessions();
        })();
    }

    useEffect(() => {
        fetchData();
    }, [asOfDate, refresh]);

    useEffect(() => {
        fetchData();
    }, []);

    const totalTime = () => {
        const sum = hourlyActiveSessions.hourlyCounts.reduce((a, b) => a + b, 0);
        return Math.round(sum / 1000)
    }
    const chartData = () => {
        const chartData = {
            labels: hourlyActiveSessions.hourlyLabels,
            datasets: [
                {
                    label: `${hourlyActiveSessions.asOfDate}`,
                    data: hourlyActiveSessions.hourlyCounts.map((e) => Math.round(e / 1000)),
                    fill: true,
                    backgroundColor: "rgb(54, 162, 235)",
                },
            ]
        }
        return chartData;
    }

    const chartOptions = () => {
        const options = {
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => { return 'Active duration: ' + formatDuration(tooltipItem.yLabel); },
                }
            },
            maintainAspectRatio: false,
            legend: {
                display: false,
                labels: {
                    boxWidth: 13
                }
            },
            scales: {
                xAxes: [
                    {
                        stacked: true
                    }
                ],
                yAxes: [
                    {
                        stacked: false,
                        ticks: {
                            beginAtZero: true,
                            callback: function (value, index, ticks) {
                                return formatDuration(value);
                            }
                        },
                    },
                ]
            },
            pan: {
                enabled: true,
                mode: "x"
            },
            zoom: {
                enabled: true,
                mode: "x",
                sensitivity: 0.5
            }
        };
        return options;
    }

    return (
        <Card className="flex-fill w-100 border">
            <CardHeader>
                <div className="d-flex w-100">
                    <CardTitle tag="h5" className="mb-0 flex-shrink-0">
                        <span className="border-bottom">Active durations</span>
                    </CardTitle>
                    <div className=" d-flex w-100 justify-content-end">
                        <div className="mr-2 font-weight-bold">
                           {hourlyActiveSessions.hourlyCounts && (<span className="mr-2">{`Total: ${formatDuration(totalTime())}`}</span>)}
                        </div>
                        <DatePicker className="ml-3" selected={asOfDate} onChange={(date) => setAsOfDate(date)} />
                        <div>
                            <Button
                                className="ml-1"
                                size="sm"
                                onClick={() => fetchData()}
                            >
                                Refresh
                            </Button>
                        </div>
                    </div>
                </div>
            </CardHeader>
            <CardBody className="pt-0 pb-0">
                <div className="chart chart-sm">
                    {hourlyActiveSessions?.hourlyCounts && <Bar data={chartData()} options={chartOptions()} />}
                </div>
            </CardBody>
        </Card>
    );
}

export default SessionDurationWidget;