import { takeLatest, call, put, takeEvery } from "redux-saga/effects";

import { sagaPromise } from "../../helpers/saga-promise-helpers";
import reportService from "../../services/reportService";
import userService from "../../services/userService";
import { alertTypes } from "../constants";
import { REPORT_MESSAGES } from "../../config/messages";

import {
  newUsersAction,
  newVideosAction,
  purchasesAction,  
  downloadedVideosAction,
  totalUsersAction,
  totalRevenuesAction,
  topPurchasedVideosAction,
  systemStorageAction,
  topSellerAction,
  topviewedVideoAction,
  usersStorageAction,
  getUserOrphanedVideosAction,
  getUserOrphanedVideoPartsAction,
  deleteUserOrphanedVideosAction,
  deleteUserOrphanedVideoPartsAction,
  recalculateUserStorageAction,
  getHourlyActiveSessionsAction
} from "../reducers/reportReducer";

function* newUsersWorker(action) {
  try {
    const { data, error } = yield call(
      reportService.newUsers,
      action.payload?.params
    );
    if (error) {
      yield put(newUsersAction.error(error));
    } else {
      yield put(newUsersAction.success({ ...action, data }));
    }
  } catch (err) {
    yield put(newUsersAction.error(err));
  }
}

function* newVideosWorker(action) {
  try {
    const { data, error } = yield call(
      reportService.newVideos,
      action.payload?.params
    );
    if (error) {
      yield put(newVideosAction.error(error));
    } else {
      yield put(newVideosAction.success({ ...action, data }));
    }
  } catch (err) {
    yield put(newVideosAction.error(err));
  }
}

function* purchasesWorker(action) {
  try {
    const { data, error } = yield call(
      reportService.purchases,
      action.payload?.params
    );
    if (error) {
      yield put(purchasesAction.error(error));
    } else {
      yield put(purchasesAction.success({ ...action, data }));
    }
  } catch (err) {
    yield put(purchasesAction.error(err));
  }
}

function* downloadedVideoReportWorker(action) {
  try {
    const { data, error } = yield call(
      reportService.downloadedVideo,
      action.payload?.params
    );
    if (error) {
      yield put(downloadedVideosAction.error(error));
    } else {
      yield put(downloadedVideosAction.success({ ...action, data }));
    }
  } catch (err) {
    yield put(downloadedVideosAction.error(err));
  }
}

function* totalUsersReportWorker(action) {
  const { data, error } = yield call(
    reportService.totalUsers
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* totalRevenueWorker(action) {
  const { data, error } = yield call(
    reportService.totalRevenues,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) throw new Error(error.headers["x-message-info"]);
  } else {
    return data;
  }

  return false;
}

function* topPurchasedVideosWorker(action) {
  const { data, error } = yield call(
    reportService.topPurchasedVideos,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) throw new Error(error.headers["x-message-info"]);
  } else {
    return data;
  }

  return false;
}

function* systemStorageReportWorker(action) {
  try {
    const { data, error } = yield call(
      reportService.systemStorage,
      action.payload?.params
    );
    if (error) {
      yield put(systemStorageAction.error(error));
    } else {
      yield put(systemStorageAction.success({ ...action, data }));
    }
  } catch (err) {
    yield put(systemStorageAction.error(err));
  }
}

function* topSellersWorker(action) {
  const { data, error } = yield call(
    reportService.topSellers,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) throw new Error(error.headers["x-message-info"]);
  } else {
    return data;
  }

  return false;
}

function* topViewedVideoWorker(action) {
  const { data, error } = yield call(
    reportService.topViewedVideos,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) throw new Error(error.headers["x-message-info"]);
  } else {
    return data;
  }

  return false;
}

function* usersStorageWorker(action) {
  const { data, error } = yield call(
    reportService.usersStorage,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) throw new Error(error.headers["x-message-info"]);
  } else {
    return data;
  }

  return false;
}

function* getUserOrphanedVideosWorker(action) {
  const { data, error } = yield call(
    reportService.getUserOrphanedVideos,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) throw new Error(error.headers["x-message-info"]);
  } else {
    return data;
  }

  return false;
}

function* getUserOrphanedVideoPartsWorker(action) {
  const { data, error } = yield call(
    reportService.getUserOrphanedVideoParts,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) throw new Error(error.headers["x-message-info"]);
  } else {
    return data;
  }

  return false;
}

function* deleteUserOrphanedVideosWorker(action) {
  const { data, error } = yield call(
    reportService.deleteUserOrphanedVideos,
    action.payload.userId
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: REPORT_MESSAGES.deleteOrphanedVideosSuccess,
    });
    return data;
  }

  return false;
}

function* deleteUserOrphanedVideoPartsWorker(action) {
  const { data, error } = yield call(
    reportService.deleteUserOrphanedVideoParts,
    action.payload.userId
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else if (data.status === "COMPLETED") {
    yield put(deleteUserOrphanedVideoPartsAction.success({ ...action, data }));
    yield put({
      type: alertTypes.SUCCESS,
      message: REPORT_MESSAGES.deleteOrphanedVideoPartsSuccess,
    });
  }
}

function* recalculateUserStorageWorker(action) {
  const { data, error } = yield call(
    reportService.recalculateUserStorage,
    action.payload.userId
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: REPORT_MESSAGES.recalculateUserStorageSuccess,
    });
    return data;
  }

  return false;
}

function* getHourlyActiveSessionsWorker(action) {
  const { data, error } = yield call(
    reportService.getHourlyActiveSessions,
    action.payload.params,
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }
  return false;
}

function* reportSaga() {
  yield takeLatest(
    newUsersAction.start,
    newUsersAction.waiterActionForSaga(sagaPromise(newUsersWorker))
  );

  yield takeLatest(
    newVideosAction.start,
    newVideosAction.waiterActionForSaga(sagaPromise(newVideosWorker))
  );

  yield takeLatest(
    purchasesAction.start,
    purchasesAction.waiterActionForSaga(sagaPromise(purchasesWorker))
  );

  yield takeLatest(
    downloadedVideosAction.start,
    downloadedVideosAction.waiterActionForSaga(sagaPromise(downloadedVideoReportWorker))
  );

  yield takeLatest(
    totalUsersAction.start,
    totalUsersAction.waiterActionForSaga(sagaPromise(totalUsersReportWorker))
  );

  yield takeLatest(
    totalRevenuesAction.start,
    totalRevenuesAction.waiterActionForSaga(sagaPromise(totalRevenueWorker))
  );

  yield takeLatest(
    topPurchasedVideosAction.start,
    topPurchasedVideosAction.waiterActionForSaga(sagaPromise(topPurchasedVideosWorker))
  );

  yield takeLatest(
    systemStorageAction.start,
    systemStorageAction.waiterActionForSaga(sagaPromise(systemStorageReportWorker))
  );

  yield takeLatest(
    topSellerAction.start,
    topSellerAction.waiterActionForSaga(sagaPromise(topSellersWorker))
  );

  yield takeLatest(
    topviewedVideoAction.start,
    topviewedVideoAction.waiterActionForSaga(sagaPromise(topViewedVideoWorker))
  );

  yield takeLatest(
    usersStorageAction.start,
    usersStorageAction.waiterActionForSaga(sagaPromise(usersStorageWorker))
  );

  yield takeLatest(
    getUserOrphanedVideosAction.start,
    getUserOrphanedVideosAction.waiterActionForSaga(sagaPromise(getUserOrphanedVideosWorker))
  );

  yield takeLatest(
    getUserOrphanedVideoPartsAction.start,
    getUserOrphanedVideoPartsAction.waiterActionForSaga(sagaPromise(getUserOrphanedVideoPartsWorker))
  );

  yield takeLatest(
    deleteUserOrphanedVideosAction.start,
    deleteUserOrphanedVideosAction.waiterActionForSaga(sagaPromise(deleteUserOrphanedVideosWorker))
  );

  yield takeLatest(
    deleteUserOrphanedVideoPartsAction.start,
    deleteUserOrphanedVideoPartsAction.waiterActionForSaga(sagaPromise(deleteUserOrphanedVideoPartsWorker))
  );

  yield takeLatest(
    recalculateUserStorageAction.start,
    recalculateUserStorageAction.waiterActionForSaga(sagaPromise(recalculateUserStorageWorker))
  );

  // yield takeLatest(newUsersAction.start, newUsersWorker);
  // yield takeLatest(newVideosAction.start, newVideosWorker);
  // yield takeLatest(revenueAction.start, revenueWorker);

  yield takeEvery(
    getHourlyActiveSessionsAction.start,
    getHourlyActiveSessionsAction.waiterActionForSaga(sagaPromise(getHourlyActiveSessionsWorker))
  );
}

export default reportSaga;
