import { takeLatest, call, put, delay } from "redux-saga/effects";

import { alertTypes } from "../constants";
import { sagaPromise } from "../../helpers/saga-promise-helpers";
import userServices from "../../services/userService";
import {
  fetchUsersAction,
  forceLogoutAction,
  changePwAction,
  resetPwAction,
  revokeRolesAction,
  searchUsersAction,
  searchMailboxesAction,
  exportMailboxesAction,
  sendEmailAction,
  enableAction,
  disableAction,
  resendActivationAction,
  importUserAction,
  deleteUserAction,
  fetchUsersAsyncAction,
  fetchUsersWithStorageAction,
  syncStoragePackageAction,
} from "../reducers/userReducer";
import { USER_MESSAGES } from "../../config/messages";

function* fetchUsersWorker(action) {
  try {
    const { data, error } = yield call(
      userServices.fetchUsers,
      action.payload?.params
    );
    if (error) {
      yield put(fetchUsersAction.error(error));
    } else {
      yield put(fetchUsersAction.success({ ...action, data }));
    }
  } catch (err) {
    yield put(fetchUsersAction.error(err));
  }
}

function* fetchUsersAsyncWorker(action) {
  const { data, error } = yield call(
    userServices.fetchUsers,
    action.payload?.params
  );
  if (error) {
    throw new Error(error.message);
  } else {
    return data;
  }
}

function* fetchUsersWithStorageWorker(action) {
  const { data, error } = yield call(
    userServices.fetchUsersWithStorage,
    action.payload?.params
  );
  if (error) {
    throw new Error(error.message);
  } else {
    return data;
  }
}

function* logoutWorker(action) {
  const { data, error } = yield call(
    userServices.forceLogout,
    action.payload.id
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: USER_MESSAGES.logoutSuccess,
    });
    return data;
  }

  return false;
}

function* changePasswordWorker(action) {
  const { data, error } = yield call(
    userServices.changePassword,
    action.payload.id,
    action.payload.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: USER_MESSAGES.changePwSuccess,
    });
    return data;
  }

  return false;
}

function* resetPasswordWorker(action) {
  const { data, error } = yield call(
    userServices.resetPassword,
    action.payload.id
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: USER_MESSAGES.resetPwSuccess,
    });
    return data;
  }

  return false;
}

function* revokeRolesWorker(action) {
  const { data, error } = yield call(
    userServices.deleteUserRoles,
    action.payload.id
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: USER_MESSAGES.revokeRolesSuccess,
    });
    return data;
  }

  return false;
}

function* searchUserWorker(action) {
  yield delay(1000);

  const { data, error } = yield call(
    userServices.fetchUsers,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* searchMailboxesWorker(action) {
  const { data, error } = yield call(
    userServices.fetchMailboxes,
    action.payload.params
  );
  yield delay(500);

  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* exportMailboxesWorker(action) {
  const { data, error } = yield call(
    userServices.exportMailboxes,
    action.payload.params
  );
  yield delay(500);

  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* sendEmailWorker(action) {
  const { data, error } = yield call(
    userServices.sendEmail,
    action.payload.emailMessage
  );
  yield delay(500);

  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* enableWorker(action) {
  const { data, error } = yield call(
    userServices.enable,
    action.payload?.userId
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: USER_MESSAGES.enableSuccess,
    });
    return data;
  }

  return false;
}

function* disableWorker(action) {
  const { data, error } = yield call(
    userServices.disable,
    action.payload?.userId
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: USER_MESSAGES.disableSuccess,
    });
    return data;
  }

  return false;
}

function* resendActivationWorker(action) {
  const { data, error } = yield call(
    userServices.confirmation,
    action.payload?.email
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: USER_MESSAGES.resendActivationSuccess,
    });
    return data;
  }

  return false;
}

function* syncUserStoragePackageWorker(action) {
  const { data, error } = yield call(
    userServices.syncUserStoragePackage,
    action.payload?.userId
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: USER_MESSAGES.syncUserStoragePackageSuccess,
    });
    return data;
  }

  return false;
}

function* deleteUsersWorker(action) {
  const { data, error } = yield call(
    userServices.deleteUser,
    action.payload?.userId,
    action.payload?.reason
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: USER_MESSAGES.deleteSuccess,
    });
    return data;
  }

  return false;
}

function* importUsersWorker(action) {
  // yield delay(3000);
  // return [];
  const { data, error } = yield call(
    userServices.importUsers,
    action.payload?.users
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }
  return false;
}

function* userSaga() {
  yield takeLatest(fetchUsersAction.start, fetchUsersWorker);

  yield takeLatest(
    fetchUsersAsyncAction.start,
    fetchUsersAsyncAction.waiterActionForSaga(
      sagaPromise(fetchUsersAsyncWorker)
    )
  );

  yield takeLatest(
    fetchUsersWithStorageAction.start,
    fetchUsersWithStorageAction.waiterActionForSaga(
      sagaPromise(fetchUsersWithStorageWorker)
    )
  );

  yield takeLatest(
    forceLogoutAction.start,
    forceLogoutAction.waiterActionForSaga(sagaPromise(logoutWorker))
  );
  yield takeLatest(
    changePwAction.start,
    changePwAction.waiterActionForSaga(sagaPromise(changePasswordWorker))
  );
  yield takeLatest(
    resetPwAction.start,
    resetPwAction.waiterActionForSaga(sagaPromise(resetPasswordWorker))
  );
  yield takeLatest(
    revokeRolesAction.start,
    revokeRolesAction.waiterActionForSaga(sagaPromise(revokeRolesWorker))
  );
  yield takeLatest(
    searchUsersAction.start,
    searchUsersAction.waiterActionForSaga(sagaPromise(searchUserWorker))
  );
  yield takeLatest(
    searchMailboxesAction.start,
    searchMailboxesAction.waiterActionForSaga(
      sagaPromise(searchMailboxesWorker)
    )
  );

  yield takeLatest(
    exportMailboxesAction.start,
    exportMailboxesAction.waiterActionForSaga(
      sagaPromise(exportMailboxesWorker)
    )
  );

  yield takeLatest(
    sendEmailAction.start,
    sendEmailAction.waiterActionForSaga(
      sagaPromise(sendEmailWorker)
    )
  );  

  yield takeLatest(
    enableAction.start,
    enableAction.waiterActionForSaga(sagaPromise(enableWorker))
  );

  yield takeLatest(
    disableAction.start,
    disableAction.waiterActionForSaga(sagaPromise(disableWorker))
  );

  yield takeLatest(
    resendActivationAction.start,
    resendActivationAction.waiterActionForSaga(
      sagaPromise(resendActivationWorker)
    )
  );

  yield takeLatest(
    importUserAction.start,
    importUserAction.waiterActionForSaga(sagaPromise(importUsersWorker))
  );

  yield takeLatest(
    deleteUserAction.start,
    deleteUserAction.waiterActionForSaga(sagaPromise(deleteUsersWorker))
  );

  yield takeLatest(
    syncStoragePackageAction.start,
    syncStoragePackageAction.waiterActionForSaga(
      sagaPromise(syncUserStoragePackageWorker)
    )
  );
}

export default userSaga;
