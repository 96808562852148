import React, { useState, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";

import {
  purchasesAction,
} from "../../../redux/reducers/reportReducer";
import { startActionWithPromise } from "../../../helpers/saga-promise-helpers";
import { formatNumber, numberToUSD } from "../../../helpers/utils";

const propTypes = {
  dateRange: PropTypes.string,
  refresh: PropTypes.number
}

const defaultProps = {
  dateRange: '',
  refresh: () => { }
}

const DAILY = "daily";
const WEEKLY = "weekly";
const MONTHLY = "monthly";

const getDayDiff = (dateRange) => {
  let tmp = dateRange.split('-');
  let dtStart = moment(tmp[0], 'MM/DD/YYYY');
  let dtEnd = moment(tmp[1], 'MM/DD/YYYY');

  return dtEnd.diff(dtStart, 'days');
}

const tabStyles = {
  revenue: {
    backgroundColor: '#6bbdc4',
    borderBottomColor: '#e4e4e4',
    color: '#fff',
    padding: '0.25rem 0.75rem'
  },
  orders: {
    backgroundColor: 'rgb(255, 99, 132)',
    borderBottomColor: '#e4e4e4',
    color: '#fff',
    padding: '0.25rem 0.75rem'
  },
}

const TooltipLabels = {
  revenue: 'Revenue',
  purchase: 'Purchase'
}

const getChartOptions = (type) => {
  return {
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
    tooltips: {
      intersect: false,
      callbacks: {
        label: function (tooltipItem) {
          if (type === 'revenue') {
            if (tooltipItem?.datasetIndex == 0)
              return `Completed: ${numberToUSD(tooltipItem.value)}`;
            return `Canceled: ${numberToUSD(tooltipItem.value)}`;
          } else {
            if (tooltipItem?.datasetIndex == 0)
              return `Completed: ${formatNumber(tooltipItem.value)}`;
            return `Canceled: ${formatNumber(tooltipItem.value)}`;
          }
        }
      }
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          stacked: false,
          ticks: {
            beginAtZero: true,
            callback: function (label) {
              return `${type === 'revenue' ? '$' : ''}${formatNumber(label)}`;
            }
            // stepSize: 20
          },
        },
      ],
      xAxes: [
        {
          stacked: false,
          gridLines: {
            color: "transparent",
          }
        },
      ],
    },
  };
}

const BarChart = ({ data, type = 'revenue' }) => {
  const options = getChartOptions(type);
  return (
    <Bar data={data} options={options} />
  )
}

const RevenueWidget = ({ theme, start: startDate, end: endDate, dateRange, refresh }) => {
  const dispatch = useDispatch();

  const dayButton = useRef(null);
  const weekButton = useRef(null);
  const monthButton = useRef(null);

  const purchases = useSelector(state => state.report.purchases);
  const purchasesReport = useSelector(state => state.report.revenues);
  const [waiting, setWaiting] = useState(false);
  const [timeline, setTimeline] = useState(DAILY);
  const [activeTab, setActiveTab] = useState(1);

  var totalRevenue = purchases.reduce(function (total, item) {
    return total + item.revenue;
  }, 0);

  var totalOrders = purchases.reduce(function (total, item) {
    return total + item.purchaseCount;
  }, 0);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const getLabels = () => {
    const source = purchasesReport[timeline] || [];
    return (source && source.map(item => item.date)) || [];
  };

  const getRevenueData = (completed) => {
    const source = purchasesReport[timeline] || [];
    return (source && source.map(item => completed ? item.revenue : item.incompleteRevenue)) || [];
  };

  const getOrderData = (completed) => {
    const source = purchasesReport[timeline] || [];
    return (source && source.map(item => completed ? item.purchaseCount : item.incompletePurchaseCount)) || [];
  };

  const data1 = {
    labels: getLabels(),
    datasets: [
      {
        label: `Completed`,
        backgroundColor: "#86c9cf",
        data: getRevenueData(true),
        maxBarThickness: 15
      },
      {
        label: `Canceled`,
        backgroundColor: "#F2B34B",
        data: getRevenueData(false),
        maxBarThickness: 15
      },
    ]
  };

  const data2 = {
    labels: getLabels(),
    datasets: [
      {
        label: `Completed`,
        backgroundColor: '#86c9cf',
        data: getOrderData(true),
        maxBarThickness: 15
      },
      {
        label: `Canceled`,
        backgroundColor: '#F2B34B',
        data: getOrderData(false),
        maxBarThickness: 15
      }
    ]
  };

  const fetchData = (start, end) => {
    (async () => {
      try {
        setWaiting(true);
        await startActionWithPromise(
          purchasesAction,
          {
            params: {
              startDate: start,
              endDate: end
            },
            successCallback: () => { },
            failedCallback: () => { },
          },
          dispatch
        );
        setWaiting(false);

        const dateDiff = getDayDiff(dateRange);
        if (dateDiff >= 180 && monthButton.current) monthButton.current.click();
        else if (dateDiff > 30 && weekButton.current) weekButton.current.click();
        else if (dateDiff <= 30 && dayButton.current) dayButton.current.click();
      } catch { }
    })();
  }

  useEffect(() => {
    const dates = dateRange.split('-');
    if (dates.length === 2) {
      fetchData(dates[0], dates[1]);
    }
  }, [refresh]);

  return (
    <Card className="flex-fill w-100 border">
      <CardHeader>
        <div className="float-right report-date-range">
          <div className="card-actions d-flex justify-content-end">
            <Button
              className="mr-1"
              color={timeline === DAILY ? "secondary" : "light"}
              size="sm"
              onClick={() => setTimeline(DAILY)}
              innerRef={dayButton}
            >
              Daily
            </Button>
            <Button
              className="mr-1"
              color={timeline === WEEKLY ? "secondary" : "light"}
              size="sm"
              onClick={() => setTimeline(WEEKLY)}
              innerRef={weekButton}
            >
              Weekly
            </Button>
            <Button
              color={timeline === MONTHLY ? "secondary" : "light"}
              size="sm"
              onClick={() => setTimeline(MONTHLY)}
              innerRef={monthButton}
            >
              Monthly
            </Button>
          </div>
        </div>

        <CardTitle tag="h5" className="mb-0">
          <div className="d-flex">
            <div><span className="border-bottom">Revenue</span></div>
            <div className="ml-4">&nbsp;</div>
            <div className="ml-4">Total revenue: <span className="font-weight-bold">{numberToUSD(totalRevenue || 0)}</span></div>
            <div className="ml-4">Total orders: <span className="font-weight-bold">{formatNumber(totalOrders || 0)}</span></div>
          </div>
        </CardTitle>
      </CardHeader>
      <CardBody className="pt-0 pb-0">
        <div className="tab tab-charts mb-0 h-100">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 1 })}
                onClick={() => toggleTab(1)}
                style={{ ...activeTab === 1 ? tabStyles.revenue : {}, padding: '0.25rem 0.75rem' }}
              >
                Revenue
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 2 })}
                onClick={() => toggleTab(2)}
                style={{ ...activeTab === 2 ? tabStyles.orders : {}, padding: '0.25rem 0.75rem' }}
              >
                Orders
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            activeTab={activeTab}
            style={{
              padding: '1rem 0 0 0',
              borderWidth: '1px 0 0 0',
              borderStyle: 'solid',
              borderColor: activeTab === 1 ? tabStyles.revenue.borderBottomColor : tabStyles.orders.borderBottomColor
            }}>
            <TabPane tabId={1}>
              {waiting ? (
                <div className="loading text-center p-2"><Spinner size="sm" /></div>
              ) : activeTab === 1 && (
                <div className="chart chart-sm">
                  <BarChart data={data1} type={'revenue'} />
                </div>
              )}
            </TabPane>
            <TabPane tabId={2}>
              {waiting ? (
                <div className="loading text-center p-2"><Spinner size="sm" /></div>
              ) : activeTab === 2 && (
                <div className="chart chart-sm">
                  <BarChart data={data2} type={'purchase'} />
                </div>
              )}
            </TabPane>
          </TabContent>
        </div>
      </CardBody>
    </Card>
  );
};

RevenueWidget.propTypes = propTypes;
RevenueWidget.defaultProps = defaultProps;

export default RevenueWidget;
