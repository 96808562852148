import {
  createReducer,
  createActionResources,
} from "redux-waiters";
import _ from "lodash";
import moment from "moment-timezone";
import { RESET_REDUCER } from "../constants";

export const newUsersAction = createActionResources("report/new-users");
export const newVideosAction = createActionResources("report/new-videos");
export const downloadedVideosAction = createActionResources("report/downloaded-videos");
export const revenueAction = createActionResources("report/revenue");
export const purchasesAction = createActionResources("report/purchases");
export const totalUsersAction = createActionResources("report/users/total");
export const totalRevenuesAction = createActionResources("report/revenues/total");
export const topPurchasedVideosAction = createActionResources("report/purchased/video");
export const topSellerAction = createActionResources("report/users/seller");
export const systemStorageAction = createActionResources("report/storages/system");
export const topviewedVideoAction = createActionResources("report/video");
export const usersStorageAction = createActionResources("report/storages/users");
export const getUserOrphanedVideosAction = createActionResources("report/user/get-orphaned-videos");
export const getUserOrphanedVideoPartsAction = createActionResources("report/user/get-orphaned-video-parts");
export const deleteUserOrphanedVideosAction = createActionResources("report/user/delete-orphaned-videos");
export const deleteUserOrphanedVideoPartsAction = createActionResources("report/user/delete-orphaned-video-parts");
export const recalculateUserStorageAction = createActionResources("report/user/recalculate-storage");
export const getHourlyActiveSessionsAction = createActionResources("report/hourly-active-sessions");

moment.tz.setDefault('UTC');

const initialState = {
  loading: false,
  deleting: false,
  users: [],
  videos: [],
  revenues: [],
  purchases: [],
  downloadedVideos: [],
  downloadsReport: [],
  systemStorages: [],
  systemStorageReport: [],
  statistics: {},
  hourlyActiveSessions: {},

};

const calcUsersData = (users) => {
  if (!users || users.length === 0) {
    return {
      daily: [],
      weekly: [],
      monthly: [],
      total: {
        active: 0,
        inactive: 0
      }
    };
  }

  var result = {
    daily: [...users],
    weekly: [],
    monthly: [],
    total: {
      active: 0,
      inactive: 0
    }
  };

  try {
    // total
    var totalActive = _.sumBy(users, function (o) { return o.userActiveCount; });
    var totalInActive = _.sumBy(users, function (o) { return o.userInactiveCount; });

    const mapDayToMonth = users.map(x => ({ ...x, month: moment(x.date, 'MM/DD/YYYY').format('MM/YYYY') }));
    const dataByMonths = mapDayToMonth.reduce((dbm, item) => {
      dbm[item.month] = {
        date: item.month,
        sort: moment(item.date, 'MM/DD/YYYY').format('YYYYMM'),
        userActiveCount: dbm[item.month] ? dbm[item.month].userActiveCount + item.userActiveCount : item.userActiveCount,
        userInactiveCount: dbm[item.month] ? dbm[item.month].userInactiveCount + item.userInactiveCount : item.userInactiveCount,
      }

      return dbm;
    }, {});

    const mapDayToWeek = users.map(x => ({ ...x, week: `${moment(x.date, 'MM/DD/YYYY').weeks()}/${moment(x.date, 'MM/DD/YYYY').year()}` }));
    const dataByWeeks = mapDayToWeek.reduce((dbw, item) => {
      dbw[item.week] = {
        date: `w${item.week}`,
        sort: `${moment(item.date, 'MM/DD/YYYY').year()}/${_.padStart(moment(item.date, 'MM/DD/YYYY').weeks(), 2, 0)}`,
        userActiveCount: dbw[item.week] ? dbw[item.week].userActiveCount + item.userActiveCount : item.userActiveCount,
        userInactiveCount: dbw[item.week] ? dbw[item.week].userInactiveCount + item.userInactiveCount : item.userInactiveCount,
      }

      return dbw;
    }, {});

    return {
      daily: [...users],
      weekly: _.sortBy(Object.values(dataByWeeks), ['sort']),
      monthly: _.sortBy(Object.values(dataByMonths), ['sort']),
      total: {
        active: totalActive,
        inactive: totalInActive,
      }
    };

  } catch (err) {
    console.log(err);
  }

  return result;
}

const calcRevenueData = (data) => {
  if (!data || data.length === 0) {
    return {
      daily: [],
      weekly: [],
      monthly: [],
      total: {
        active: 0,
        inactive: 0
      }
    };
  }

  var result = {
    daily: [...data],
    weekly: [],
    monthly: [],
    total: {
      active: 0,
      inactive: 0
    }
  };

  try {
    // total
    var totalActive = _.sumBy(data, function (o) { return o.purchaseCount; });
    var totalInActive = _.sumBy(data, function (o) { return o.revenue; });

    const mapDayToMonth = data.map(x => ({ ...x, month: moment(x.date, 'MM/DD/YYYY').format('MM/YYYY') }));
    const dataByMonths = mapDayToMonth.reduce((dbm, item) => {
      dbm[item.month] = {
        date: item.month,
        sort: moment(item.date, 'MM/DD/YYYY').format('YYYYMM'),
        purchaseCount: dbm[item.month] ? dbm[item.month].purchaseCount + item.purchaseCount : item.purchaseCount,
        revenue: dbm[item.month] ? dbm[item.month].revenue + item.revenue : item.revenue,
        incompletePurchaseCount: dbm[item.month] ? dbm[item.month].incompletePurchaseCount + item.incompletePurchaseCount : item.incompletePurchaseCount,
        incompleteRevenue: dbm[item.month] ? dbm[item.month].incompleteRevenue + item.incompleteRevenue : item.incompleteRevenue,
      }

      return dbm;
    }, {});

    const mapDayToWeek = data.map(x => ({ ...x, week: `${moment(x.date, 'MM/DD/YYYY').weeks()}/${moment(x.date, 'MM/DD/YYYY').year()}` }));
    const dataByWeeks = mapDayToWeek.reduce((dbw, item) => {
      dbw[item.week] = {
        date: `w${item.week}`,
        sort: `${moment(item.date, 'MM/DD/YYYY').year()}/${_.padStart(moment(item.date, 'MM/DD/YYYY').weeks(), 2, 0)}`,
        purchaseCount: dbw[item.week] ? dbw[item.week].purchaseCount + item.purchaseCount : item.purchaseCount,
        revenue: dbw[item.week] ? dbw[item.week].revenue + item.revenue : item.revenue,
        incompletePurchaseCount: dbw[item.week] ? dbw[item.week].incompletePurchaseCount + item.incompletePurchaseCount : item.incompletePurchaseCount,
        incompleteRevenue: dbw[item.week] ? dbw[item.week].incompleteRevenue + item.incompleteRevenue : item.incompleteRevenue,
      }

      return dbw;
    }, {});

    return {
      daily: [...data],
      weekly: _.sortBy(Object.values(dataByWeeks), ['sort']),
      monthly: _.sortBy(Object.values(dataByMonths), ['sort']),
      total: {
        active: totalActive,
        inactive: totalInActive,
      }
    };

  } catch (err) {
    console.log(err);
  }

  return result;
}

const calcDownloadData = (data) => {
  if (!data || data.length === 0) {
    return {
      daily: [],
      weekly: [],
      monthly: [],
      total: {
        active: 0,
        inactive: 0
      }
    };
  }

  var result = {
    daily: [...data],
    weekly: [],
    monthly: [],
    total: {
      active: 0,
      inactive: 0
    }
  };

  try {
    // total
    var freeDownloadCount = _.sumBy(data, function (o) { return o.freeDownloadCount; });
    var paidDownloadCount = _.sumBy(data, function (o) { return o.paidDownloadCount; });

    const mapDayToMonth = data.map(x => ({ ...x, month: moment(x.date, 'MM/DD/YYYY').format('MM/YYYY') }));
    const dataByMonths = mapDayToMonth.reduce((dbm, item) => {
      dbm[item.month] = {
        date: item.month,
        sort: moment(item.date, 'MM/DD/YYYY').format('YYYYMM'),
        freeDownloadCount: dbm[item.month] ? dbm[item.month].freeDownloadCount + item.freeDownloadCount : item.freeDownloadCount,
        paidDownloadCount: dbm[item.month] ? dbm[item.month].paidDownloadCount + item.paidDownloadCount : item.paidDownloadCount,
      }

      return dbm;
    }, {});

    const mapDayToWeek = data.map(x => ({ ...x, week: `${moment(x.date, 'MM/DD/YYYY').weeks()}/${moment(x.date, 'MM/DD/YYYY').year()}` }));
    const dataByWeeks = mapDayToWeek.reduce((dbw, item) => {
      dbw[item.week] = {
        date: `w${item.week}`,
        sort: `${moment(item.date, 'MM/DD/YYYY').year()}/${_.padStart(moment(item.date, 'MM/DD/YYYY').weeks(), 2, 0)}`,
        freeDownloadCount: dbw[item.week] ? dbw[item.week].freeDownloadCount + item.freeDownloadCount : item.freeDownloadCount,
        paidDownloadCount: dbw[item.week] ? dbw[item.week].paidDownloadCount + item.paidDownloadCount : item.paidDownloadCount,
      }

      return dbw;
    }, {});

    return {
      daily: [...data],
      weekly: _.sortBy(Object.values(dataByWeeks), ['sort']),
      monthly: _.sortBy(Object.values(dataByMonths), ['sort']),
      total: {
        freeDownloadCount: freeDownloadCount,
        paidDownloadCount: paidDownloadCount,
      }
    };

  } catch (err) {
    console.log(err);
  }

  return result;
}

const getSystemStorageReport = (data) => {
  const defaultReport = {
    daily: [],
    weekly: [],
    monthly: [],
    total: {
      storage: 0,
    }
  };
  if (!data || data.length === 0) {
    return defaultReport;
  }

  var result = {
    ...defaultReport,
    daily: [...data],
  };

  try {
    // total
    var totalStorageUsed = _.sumBy(data, function (o) { return o.storageTotal; });

    let weekly = [];
    let monthly = [];
    let isFirst = true;
    for (const item of data) {
      let mDate = moment(item.date, 'MM/DD/YYYY');
      let endOfWeek = mDate.clone().endOf('week');
      let endOfMonth = mDate.clone().endOf('month');

      let weekDate = `w${endOfWeek.weeks()}/${endOfWeek.year()}`;
      if (mDate.format('MM/DD/YYYY') === endOfWeek.format('MM/DD/YYYY') && !weekly.find(x => x.date === weekDate))
        weekly.push({ date: weekDate, storage: item.storageTotal });

      let monthDate = endOfMonth.format('MM/YYYY');
      if (mDate.format('MM/DD/YYYY') === endOfMonth.format('MM/DD/YYYY') && !monthly.find(x => x.date === monthDate))
        monthly.push({ date: monthDate, storage: item.storageTotal });

      isFirst = false;
    }

    let lastItem = data.pop();
    let mDate = moment(lastItem.date, 'MM/DD/YYYY');
    let endOfWeek = mDate.clone();
    let endOfMonth = mDate.clone();

    let weekDate = `w${endOfWeek.weeks()}/${endOfWeek.year()}`;
    if (!weekly.find(x => x.date === weekDate))
      weekly.push({ date: weekDate, storage: lastItem.storageTotal });

    let monthDate = endOfMonth.format('MM/YYYY');
    if (!monthly.find(x => x.date === monthDate))
      monthly.push({ date: monthDate, storage: lastItem.storageTotal });

    return {
      daily: [...data],
      weekly: weekly,
      monthly: monthly,
      total: {
        systemStorage: totalStorageUsed
      }
    };
  } catch (err) {
    console.log(err);
  }

  return result;
}

export default createReducer(
  {
    [newUsersAction.start]: state => {
      return { ...state, loading: true };
    },
    [newUsersAction.success]: (state, action) => {
      const userStats = calcUsersData(action.data);
      return {
        ...state,
        users: userStats,
        loading: false,
      };
    },
    [newUsersAction.error]: state => {
      return { ...state, loading: false };
    },

    [purchasesAction.start]: state => {
      return { ...state, loading: true };
    },
    [purchasesAction.success]: (state, action) => {
      const purchasesReport = calcRevenueData(action.data);

      return {
        ...state,
        purchases: action.data || [],
        revenues: purchasesReport,
        loading: false,
      };
    },
    [purchasesAction.error]: state => {
      return { ...state, loading: false };
    },

    [newVideosAction.start]: state => {
      return { ...state, loading: true };
    },
    [newVideosAction.success]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    [newVideosAction.error]: state => {
      return { ...state, loading: false };
    },
    [downloadedVideosAction.start]: state => {
      return { ...state, loading: true };
    },
    [downloadedVideosAction.success]: (state, action) => {
      return {
        ...state,
        downloadedVideos: action.data,
        downloadsReport: calcDownloadData(action.data),
        loading: false,
      };
    },
    [downloadedVideosAction.error]: state => {
      return { ...state, loading: false };
    },

    [systemStorageAction.start]: state => {
      return { ...state, loading: true };
    },
    [systemStorageAction.success]: (state, action) => {
      return {
        ...state,
        systemStorages: action.data,
        systemStorageReport: getSystemStorageReport(action.data),
        loading: false,
      };
    },
    [systemStorageAction.error]: state => {
      return { ...state, loading: false };
    },

    [deleteUserOrphanedVideoPartsAction.start]: state => {
      return { ...state, deleting: true };
    },
    [deleteUserOrphanedVideoPartsAction.success]: (state, action) => {
      return {
        ...state,
        ...(action.data.status === "COMPLETED" ? { deleting: false } : {})
      };
    },

    [RESET_REDUCER]: () => initialState,
  },
  initialState
);
